// PurchaseForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { format, parse, isValid, subWeeks } from 'date-fns';
import firstNamesData from './mocks/firstName.json';
import lastNamesData from './mocks/lastName.json';
import PurchaseFormView from './PurchaseFormView'; // Import the view component

// Helper function to format a date from YYYY-MM-DD to dd.MM.yyyy
const formatDateForDisplay = (date) => format(new Date(date), 'dd.MM.yyyy');

// Helper function to get today's date in YYYY-MM-DD format
const getCurrentDate = () => format(new Date(), 'yyyy-MM-dd');

// Helper function to calculate the purchase date based on months input
const calculateDateWithMonthsAndWeeks = (monthsValue, referenceDate = new Date()) => {
  const finalCalculatedDate = subWeeks(referenceDate, monthsValue * 4 - 4);
  return finalCalculatedDate;
};

const PurchaseForm = () => {
  const [formData, setFormData] = useState({
    partnerCode: '',
    customPartnerCode: '',
    firstName: '',
    lastName: '',
    email: '',
    orderNr: '',
    purchasedAt: formatDateForDisplay(getCurrentDate()), // Set initial value in dd.MM.yyyy format
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State to show error message from server
  const [isCustomPartnerCode, setIsCustomPartnerCode] = useState(false); // State to track if "Other" is selected
  const [showDatePicker, setShowDatePicker] = useState(false); // State for showing/hiding date picker
  const [isDateInvalid, setIsDateInvalid] = useState(false); // State for date validation
  const [monthsValue, setMonthsValue] = useState(''); // State for tracking inputted months
  const [calculatedPastDate, setCalculatedPastDate] = useState(null); // State for calculated purchase date in the past
  const [generateRandomAfterCreate, setGenerateRandomAfterCreate] = useState(false); // State for the new checkbox

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle partner code change
  const handlePartnerCodeChange = (e) => {
    const selectedValue = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      partnerCode: selectedValue,
    }));

    if (selectedValue === 'other') {
      setIsCustomPartnerCode(true);
    } else {
      setIsCustomPartnerCode(false);
    }
  };

  // Handle Random Fill
  const handleRandomFill = () => {
    const randomFirstName = firstNamesData.firstNames[Math.floor(Math.random() * firstNamesData.firstNames.length)];
    const randomLastName = lastNamesData.lastNames[Math.floor(Math.random() * lastNamesData.lastNames.length)];
    const randomEmail = `${randomFirstName.toLowerCase()}.${randomLastName.toLowerCase()}@staging-environment.de`;
    const randomOrderNr = '0'+Math.floor(Date.now() / 1000).toString(); // Unix timestamp in seconds

    setFormData((prevData) => ({
      ...prevData,
      firstName: randomFirstName,
      lastName: randomLastName,
      email: randomEmail,
      orderNr: randomOrderNr,
    }));
  };

  // Handle Months Input Change
  const handleMonthsInput = (e) => {
    const months = parseInt(e.target.value, 10);
    setMonthsValue(e.target.value);

    if (!isNaN(months) && months > 0) {
      const pastDate = calculateDateWithMonthsAndWeeks(months);
      setCalculatedPastDate(pastDate);
    } else {
      setCalculatedPastDate(null);
    }
  };

  // Handle Take Calculated Date
  const handleTakeDate = () => {
    if (calculatedPastDate) {
      setFormData((prevData) => ({
        ...prevData,
        purchasedAt: format(calculatedPastDate, 'dd.MM.yyyy'),
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');
    setIsDateInvalid(false);

    const partnerCodeValue = isCustomPartnerCode ? formData.customPartnerCode : formData.partnerCode;

    // Validate partnerCode
    if (!partnerCodeValue || partnerCodeValue === '') {
      setErrorMessage('Invalid partner code selected');
      setIsSubmitting(false);
      return;
    }

    try {
      // Validate date before submission
      const parsedDate = parse(formData.purchasedAt, 'dd.MM.yyyy', new Date());
      if (!isValid(parsedDate)) {
        setIsDateInvalid(true);
        setIsSubmitting(false);
        return;
      }

      const formattedFormData = {
        ...formData,
        partnerCode: partnerCodeValue,
        purchasedAt: format(parsedDate, 'yyyy-MM-dd'),
      };

      await axios.post(`${process.env.REACT_APP_API_URL}/purchase/create`, formattedFormData, { withCredentials: true });

      setSuccessMessage(`Purchase Order ${formData.orderNr} created successfully!`);
      setIsSubmitting(false);

      if (generateRandomAfterCreate) {
        handleRandomFill(); // Generate random values after submission if the checkbox is checked
      } else {
        setFormData((prevData) => ({
          ...prevData,
          firstName: '',
          lastName: '',
          email: '',
          orderNr: '',
          purchasedAt: formatDateForDisplay(getCurrentDate()),
        }));
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error creating purchase.');
      setIsSubmitting(false);
    }
  };

  // Handle form reset/clear
  const handleClear = () => {
    setFormData((prevData) => ({
      ...prevData,
      firstName: '',
      lastName: '',
      email: '',
      orderNr: '',
      purchasedAt: formatDateForDisplay(getCurrentDate()),
    }));
    setIsDateInvalid(false);
    setSuccessMessage('');
    setErrorMessage('');
    setMonthsValue(''); // Reset months value to empty
    setCalculatedPastDate(null); // Reset calculated date
  };

  // Handle checkbox change for "Generate Random after Create"
  const handleCheckboxChange = (e) => {
    setGenerateRandomAfterCreate(e.target.checked);
  };

  return (
    <PurchaseFormView
      formData={formData}
      isSubmitting={isSubmitting}
      successMessage={successMessage}
      errorMessage={errorMessage}
      isDateInvalid={isDateInvalid}
      isCustomPartnerCode={isCustomPartnerCode}
      showDatePicker={showDatePicker}
      handleChange={handleChange}
      handlePartnerCodeChange={handlePartnerCodeChange}
      handleRandomFill={handleRandomFill}
      handleSubmit={handleSubmit}
      handleClear={handleClear}
      handleMonthsInput={handleMonthsInput}
      handleTakeDate={handleTakeDate}
      setShowDatePicker={setShowDatePicker}
      monthsValue={monthsValue}
      calculatedPastDate={calculatedPastDate}
      generateRandomAfterCreate={generateRandomAfterCreate} // Pass the new state as prop
      handleCheckboxChange={handleCheckboxChange} // Pass the handler as prop
    />
  );
};

export default PurchaseForm;
