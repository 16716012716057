// PurchaseFormView.jsx
import React from 'react';
import { Container, Form, Button, Alert, Row, Col, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, format } from 'date-fns'; // Import parse and format functions

const PurchaseFormView = ({
  formData,
  isSubmitting,
  successMessage,
  errorMessage,
  isDateInvalid,
  isCustomPartnerCode,
  showDatePicker,
  handleChange,
  handlePartnerCodeChange,
  handleRandomFill,
  handleSubmit,
  handleClear,
  handleMonthsInput,
  handleTakeDate,
  setShowDatePicker,
  monthsValue,
  calculatedPastDate,
  generateRandomAfterCreate, // Receive the state as prop
  handleCheckboxChange, // Receive the handler as prop
}) => (
  <Container className="container-sm mt-5 mw-sm" style={{ position: 'relative' }}>
    <h2 className="text-center mb-4">Create New Purchase</h2>
    <Form onSubmit={handleSubmit} className={`purchase-form ${isSubmitting ? 'loading' : ''}`}>
      <Form.Group controlId="partnerCode" className="mb-3">
        <Form.Label>Partner Code</Form.Label>
        <Form.Control
          as="select"
          name="partnerCode"
          value={formData.partnerCode}
          onChange={handlePartnerCodeChange}
          disabled={isSubmitting}
          required
        >
          <option value="">Select a partner code</option>
          <option value="0000808801">0000808801</option>
          <option value="0000333333">0000333333</option>
          <option value="other">Other</option>
        </Form.Control>
        {isCustomPartnerCode && (
          <Form.Control
            type="text"
            name="customPartnerCode"
            value={formData.customPartnerCode}
            onChange={handleChange}
            placeholder="Enter custom partner code"
            className="mt-3"
            disabled={isSubmitting}
            required
          />
        )}
      </Form.Group>

      {/* First Name Field */}
      <Form.Group controlId="firstName" className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="Enter First Name"
          disabled={isSubmitting}
          required
        />
      </Form.Group>

      {/* Last Name Field */}
      <Form.Group controlId="lastName" className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Enter Last Name"
          disabled={isSubmitting}
          required
        />
      </Form.Group>

      {/* Email Field */}
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter Email"
          disabled={isSubmitting}
          required
        />
      </Form.Group>

      {/* Order Number Field */}
      <Form.Group controlId="orderNr" className="mb-3">
        <Form.Label>Order Number</Form.Label>
        <Form.Control
          type="text"
          name="orderNr"
          value={formData.orderNr}
          onChange={handleChange}
          placeholder="Enter Order Number"
          disabled={isSubmitting}
          required
        />
      </Form.Group>

      {/* Purchase Date Field */}
      <Form.Group controlId="purchasedAt" className="mb-3">
        <Form.Label>Purchase Date</Form.Label>
        <div className="d-flex">
          <Form.Control
            type="text"
            name="purchasedAt"
            value={formData.purchasedAt}
            onChange={handleChange}
            placeholder="dd.MM.yyyy"
            disabled={isSubmitting}
            required
            className={isDateInvalid ? 'is-invalid' : ''}
          />
          <Button
            variant="outline-secondary"
            onClick={() => setShowDatePicker(!showDatePicker)}
            disabled={isSubmitting}
            className="ms-2"
          >
            📅
          </Button>
        </div>
        {isDateInvalid && (
          <div className="text-danger mt-1">Please enter a valid date in the format dd.MM.yyyy.</div>
        )}
        {showDatePicker && (
          <DatePicker
            selected={parse(formData.purchasedAt, 'dd.MM.yyyy', new Date())}
            onChange={(date) => {
              handleChange({ target: { name: 'purchasedAt', value: format(date, 'dd.MM.yyyy') } });
              setShowDatePicker(false);
            }}
            dateFormat="dd.MM.yyyy"
            inline
          />
        )}
      </Form.Group>

      {/* Months Input Field */}
      <Form.Group controlId="monthsInput" className="mt-4 mb-3">
        <Form.Label className="h6">Calculate Purchase Date Based on Months in the Past</Form.Label>
        <Row>
          <Col xs={12} md={6}>
            <Form.Control
              className="mb-2"
              type="number"
              placeholder="Months value"
              value={monthsValue}
              onChange={handleMonthsInput}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <div className="text-end">
              <Button
                className="w-100 btn-sm btn-block"
                variant="outline-primary"
                onClick={handleTakeDate}
                disabled={!calculatedPastDate || isSubmitting}
              >
                Take Calculated Date
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <small>
              Calculated Date: {!calculatedPastDate && <span>-</span>}
              {calculatedPastDate && <strong> {format(calculatedPastDate, 'dd.MM.yyyy')} </strong>}
            </small>
          </Col>
        </Row>
      </Form.Group>

      {/* Checkbox for Generating Random After Create */}
      <Form.Group controlId="generateRandomAfterCreate" className="mb-4">
        <Form.Check
          type="checkbox"
          label="Generate Random after Create"
          checked={generateRandomAfterCreate} // Bind to state
          onChange={handleCheckboxChange} // Handle changes
          disabled={isSubmitting}
        />
      </Form.Group>

      {/* Action Buttons */}
      <hr />
      <Row className="mb-3 mt-5">
        <Col sm={12} md={4}>
          <Button variant="secondary" className="w-100 mb-3" onClick={handleRandomFill} disabled={isSubmitting}>
            Random
          </Button>
        </Col>
        <Col sm={12} md={4}>
          <Button variant="outline-secondary" className="mb-3 w-100" onClick={handleClear} disabled={isSubmitting}>
            Clear
          </Button>
        </Col>
        <Col sm={12} md={4}>
          <Button variant="primary" type="submit" className="w-100" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Create'}
          </Button>
        </Col>
      </Row>
    </Form>

    {/* Overlay with Spinner */}
    {isSubmitting && (
      <div className="overlay">
        <Spinner animation="border" variant="primary" />
      </div>
    )}

    {/* Success Message */}
    {successMessage && (
      <Alert variant="success" className="mt-4">
        {successMessage}
      </Alert>
    )}

    {/* Error Message */}
    {errorMessage && (
      <Alert variant="danger" className="mt-4">
        {errorMessage}
      </Alert>
    )}
  </Container>
);

export default PurchaseFormView;
